angular.module("app")
    .controller("activationController", ["$log", "$stateParams", "$state", "$window", "userService", "Notification", function ($log, $stateParams, $state, $window, userService, Notification) {
        var ctrl = this;
        ctrl.activation = undefined;
        ctrl.activationToken = $stateParams.activationToken;
        ctrl.pageNotReady = true;
        ctrl.showPassword = false;

        (function init() {
            $log.info("activation controller initialised");
            loadAuthentication();
        })();

        ctrl.toggleShowPassword = function() {
            ctrl.showPassword = !ctrl.showPassword;
        };

        ctrl.submit = function () {
            if(!validatePassword(ctrl.activation.password)) {
                Notification.error({message: "Password entered does not meet the required criteria"});
                return;
            }

            ctrl.pageNotReady = true;


            userService.submitActivation(ctrl.activation).then(function (result) {
                $window.location.href = "/dashboard/";
            }).catch(function (result) {
                Notification.error({message: 'There was a problem activating this account', delay: 5000});
            }).finally(function () {
                ctrl.pageNotReady = false;
            });
        };

        function loadAuthentication() {
            userService.getActivation(ctrl.activationToken).then(function (activation) {
                    $log.info(activation);
                    ctrl.activation = {
                        username: activation.emailAddress,
                        password: undefined,
                        activationKey: ctrl.activationToken
                    };
                }
            ).catch(function (result) {
                Notification.error({message: 'Sorry we were unable to activate this account', delay: 5000});
            }).finally(function () {
                ctrl.pageNotReady = false;
            });
        }

        function validatePassword(pw) {
            var criteria = 0;
            if (pw.toUpperCase() != pw) {
                // has lower case letters
                criteria++;
            }
            if (pw.toLowerCase() != pw) {
                // has upper case letters
                criteria++;
            }
            if (/^[a-zA-Z0-9]*$/.test(pw) === false) {
                // has special characters
                criteria++;
            }
            if (/\d/.test(pw) === true) {
                // has numbers
                criteria++;
            }
            // returns true if 3 or more criteria was met and length is appropriate
            return (criteria >= 3 && pw.length >= 10 && pw.length <= 128);
        }
    }]);